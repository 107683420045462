import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import accountListGIF from '../images/crm-accounts-list-scroll.gif'
import accountRelatedListGIF from '../images/crm-related-accounts-scroll.gif'
import Arrow from 'react-arrow'


const crmaccounts = ({data}) => (
    <Layout>
        <SEO title="CRM Accounts Redesign" />
        <div className="Hero">
            <div className="HeroImage">
                <Img fluid={data.accountsHero.childImageSharp.fluid} alt="CRM Accounts Hero Image" />
             </div>
        </div>
        <div className="PageContent">
            <h1>CRM Accounts Redesign</h1>
            <h4>Time: 2017</h4>

            <h2>Background</h2>
            <p><a href="https://www.veeva.com/products/multichannel-crm/crm/">Veeva CRM Suite</a> is a multi-platform customers relationship management platform tailored for global life science industry. I was part of Veeva CRM's product design team working on CRM iPad app redesign from early 2017 to mid 2018. Meanwhile, we also designed the first iPhone app (MVP) with limited but core functionalities. Accounts was part of the initial iPhone scope. </p>

            <h2>Problem</h2>
            <p>As the product grew rapidly in the past 10 years, the old design has <strong>struggled to scale</strong>. Usability problems increased, navigation became a pain for end users (sales reps). There were too many UI patterns in the app that cause confusions. </p>

            <h2>Challenges</h2>
            <ul className="list">
                <li><strong>Legacy</strong> -It's challenging to overthrow the heritage. In fact, sunsetting any old features was extremely difficult even if only one or two customers are using it. How might we keep the same functionalities but create a new framework with better user experience?</li>
                <li><strong>It's a complicated system</strong> - Veeva CRM is a highly configurable product that contains regulatory requirements and compliances. Any new UI pattern needs to consider all business flows and use cases.</li>
                <li><strong>It takes efforts to talk to end users</strong> - The concept of user research was new to many of our customers, the team tried hard to convince buyers (admins) to allow us to talk to end users (sales reps). In the meantime our customer success team helped giving feedback based on their conversation with customers and the PMs shared their insights to help us understand user journey. </li>
                <li><strong>Internationalization</strong> - Design with 20+ languages in mind. The new design has to work well in a condensed language like Chinese and in longer text languages like Russian and German. </li>
            </ul>

            <h2>Goal</h2>
            <ul className="list">
                <li>Create a new UI framework to streamline navigation patterns and enhance discoverability.</li>
                <li>Redefine visual styles and elements to build a new design system.</li>
                <li>Re-organize and enhance features with a clear UI hierarchy to bring the<strong> balance between complexity and simplicity</strong>. </li>
                <li><strong>Streamline the process</strong> to accomplish tasks (navigation).</li>
                <li>Enhance views of data.</li>
                <li>Create <strong>cohesive styles and patterns</strong> across platforms.</li>
            </ul>
            <div className="ImageWrapper">
                <Img fluid={data.accountsIA.childImageSharp.fluid} alt="CRM Overall Information Architecture" />
            </div>

            <h2>My Work with Accounts</h2>
            <div className="ImageWrapper">
                <Img fluid={data.accountsWhiteboarding.childImageSharp.fluid} alt="CRM Accounts Whiteboarding" />
            </div>
            <p>Accounts was the first app area I explored the new framework with, since Accounts is part of the core features as well as it shares similarities with many other app areas. In parallel I started working on the Accounts in iPhone app.</p>
            <p>Accounts allows users to view, search and filter a list of aligned healthcare practitioners and organizations with detailed profile information. Users can perform actions against their accounts such as report a Call, send emails or send samples. </p>
            <div className="ImageWrapper">
                <Img fluid={data.accountsMenuChange.childImageSharp.fluid} alt="CRM Accounts Nav Change" />
            </div>
            <p>The old UI layout misused the native iOS split view and caused navigational problems. It also took 1/3 of screen real estate that customers complained. Instead of a hamburger menu we decided to use the native iOS tab bar as it's more discoverable and faster to access the important tabs.</p>
            <div className="ImageWrapper">
                <Img fluid={data.accountsProgress.childImageSharp.fluid} alt="CRM Accounts Design Progress" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.accountsFinalLayout.childImageSharp.fluid} alt="CRM Accounts Final Layout" />
            </div>
            <p>The finalized layout is the foundation of the CRM Sunrise redesign. Critical page actions are on the right side of top nav bar, such as Edit, Create a Call. Sunrise Bar may contain configurable tabs to switch views like List View, Map View. In content area there might be actions based on Sunrise Bar views and these actions are placed on top of the content card.</p>

            <h2>Usability Testing</h2>
            <div className="ImageWrapper">
                <Img fluid={data.accountsTesting.childImageSharp.fluid} alt="CRM Accounts User Testing" />
            </div>
            <p>In December 2017, the team performed two user testing sessions with the new Accounts design prototype. The purpose of the usability test was to test out the general navigation,  patterns and to find out whether the new UI elements were understandable.</p>
            <p>Below are a few main research questions:</p>
            <ul className="list">
                <li>Is the overall navigation making sense? Can people find things quickly?</li>
                <li>Do people understand the meanings of new icons?</li>
                <li>Is the new design making the tasks easier to complete?</li>
            </ul>

            <h2>What We Learned</h2>
            <p>The overall feedback from user testing sessions was positive. Users complimented the new UI is modern and clean, the content is richer, some old features got improved and they found it easier to use. We also found <strong>users were open to changes that make their jobs easier</strong>, versus their IT managers pushed back more on new things to avoid additional training efforts.</p>
            <p>We also realized there were two typical user types and they reacted differently: </p>
            <ul className="list">
                <li>People (tend to be younger ages) who understand native iOS behaviors and modern technologies. They are <strong>faster learners</strong>, some of them are pro users who <strong>use more complex features</strong>.</li>
                <li>People who <strong>only use simple basic features</strong>. Many of they are middle aged peole who are late adopter of new features, they want the app to be <strong>very explicit and simple</strong>.</li>
            </ul>
            <p>In the final design, I addressed some of the feedback from the later user type by making critical data more prominent, having primary action buttons such as Create a Call redundantly appearing on the top navigation bar and inside the general action list with explicit labels. I also added more visual clues to guide users such as showing an arrow on the Sunrise Bar to indicate scroll to see more.</p>

            <h2>Final Design</h2>
            <div className="ImageWrapper">
                <Img fluid={data.accountsFinal1.childImageSharp.fluid} alt="CRM Accounts Final Design - iPad and iPhone" />
            </div>
            <div className="ImageWrapper">
                <Img fluid={data.accountsFinal2.childImageSharp.fluid} alt="CRM Accounts Final Design - Rest iPad " />
            </div>
            <div className="AccountsGifWrapper">
                <img src={accountListGIF} alt="CRM Accounts List Scroll Animation" width="300px"/>
            </div>
            <div className="AccountsGifWrapper">
                <img src={accountRelatedListGIF} alt="CRM Accounts Related List Scroll Animation" width="300px"/>
            </div>

        </div>

        <div className="BottomNav">
          <div className="leftNav" >
            <Link to="/learningpathway"><Arrow className="arrow"
            direction="left"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />Previous project
            </Link>
          </div>
          <div className="rightNav">
            <Link to="/crmmedia">Next project<Arrow className="arrow"
            direction="right"
            shaftWidth={2}
            shaftLength={8}
            headWidth={10}
            headLength={8}
            />
            </Link>
          </div>
        </div>

    </Layout>

)

export default crmaccounts 

export const query = graphql`
query {
    accountsHero: file(relativePath: {eq: "crm-accounts-hero.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsIA: file(relativePath: {eq: "crm-accounts-ia.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsWhiteboarding: file(relativePath: {eq: "crm-accounts-whiteboard.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsMenuChange: file(relativePath: {eq: "crm-accounts-menu-change.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsProgress: file(relativePath: {eq: "crm-accounts-design-progress.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsTesting: file(relativePath: {eq: "crm-accounts-user-testing.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsFinal1: file(relativePath: {eq: "crm-accounts-final-design1.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsFinal2: file(relativePath: {eq: "crm-accounts-final-design2.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }
      accountsFinalLayout: file(relativePath: {eq: "crm-accounts-final-layout.png"}) {
        childImageSharp {
          fluid(maxWidth: 1112) {
           ...GatsbyImageSharpFluid
          }
        }
      }


}

`
 

